import request from '../utils/request';
import qs from "qs";

// AXB列表查询
export const setMealList = id => {
	return request.get('/api/setMeal/list')
};
// AXB添加
export const setMealAdd = param => {
	return request.post('/api/setMeal/add', qs.stringify(param))
};
// AXB修改
export const setMealUpdate = param => {
	return request.post('/api/setMeal/update', qs.stringify(param))
};
//AXB删除
export const setMealDelete = param => {
	return request.post('/api/setMeal/delete', qs.stringify(param))
};


// 虚商AXB添加
export const setMealAddX = param => {
	return request.post('/api/xsAxb/setMeal/add', qs.stringify(param))
};
// 虚商AXB修改
export const setMealUpdateX = param => {
	return request.post('/api/xsAxb/setMeal/update', qs.stringify(param))
};
//虚商AXB删除
export const setMealDeleteX = param => {
	return request.post('/api/xsAxb/setMeal/delete', qs.stringify(param))
};

//虚商AXB绑定关系查询
export const Bingding = param => {
	return request.post('/api/xsAxb/numberRelationship', qs.stringify(param))
};


// 虚商AXB套餐列表
export const setMealListX = id => {
	return request.get('/api/xsAxb/setMeal/list')
};
//虚商AXB =  AX绑定
export const numberInit = param => {
	return request.post('/api/xsAxb/numberInit', qs.stringify(param))
};

//虚商AXB =  购买套餐
export const recharge = param => {
	return request.post('/api/xsAxb/recharge', qs.stringify(param))
};


//退卡
export const returnCard = param => {
	return request.post('/api/SJSZInventory/returnCard', qs.stringify(param))
};

//库存列表
export const inventoryList = param => {
	return request.post('/api/inventory/list', qs.stringify(param))
};

// 添加库存
export const inventoryUpload = param => {
	return request.post('/api/inventory/upload', qs.stringify(param), {
		responseType: 'blob'
	})
};

// 划拨号码给企业
export const inventoryOptionalNumber = param => {
	return request.post('/api/inventory/optionalNumber', qs.stringify(param))
};
// 查看已划拨号码
export const inventoryFindAssigned = param => {
	return request.post('/api/inventory/findAssigned', qs.stringify(param))
};

// 选购套餐
export const inventoryChoosePackage = param => {
	return request.post('/api/inventory/choosePackage', qs.stringify(param))
};

// 绑定
export const axbBind = param => {
	return request.post('/api/axb/bind', qs.stringify(param))
};
// 坐席续费
export const addDuration = param => {
	return request.post('/api/inventory/addDuration', qs.stringify(param))
};
// 解绑
export const axbUnbind = param => {
	return request.post('/api/axb/unbind', qs.stringify(param))
};
// 支付反馈
export const inventoryCheckPay = param => {
	return request.post('/api/inventory/checkPay', qs.stringify(param))
};


//AXB消费明细
export const inventoryExpenseDetail = param => {
	return request.post('/api/inventory/expenseDetail', qs.stringify(param))
};




// 所有地区号码
export const inventoryStatistics = id => {
	return request.get('/api/inventory/statistics')
};



// 导出回收客户
export const downloadCustomer = param => {
	return request.post('/api/customer/downloadCustomer', qs.stringify(param), {
		responseType: 'blob'
	})
};
// 分配回收客户
export const allotCustomers = param => {
	return request.post('/api/customer/allotCustomers', param, {
		headers: {
			'Content-Type': 'application/json'
		}
	})
};
//世纪通信库存
export const SJSZInventoryList = param => {
	return request.post('/api/SJSZInventory/list', qs.stringify(param))
};

//世纪通信库存添加
export const SJSZInventoryAdd = param => {
	return request.post('/api/SJSZInventory/add', qs.stringify(param))
};
//世纪通信库存  出库
export const SJSZInventoryDeliveryStorage = param => {
	return request.post('/api/SJSZInventory/DeliveryStorage', qs.stringify(param))
};
//世纪通信库存  出库记录
export const SJSZInventoryDeliveryRecord = param => {
	return request.post('/api/SJSZInventory/DeliveryRecord', qs.stringify(param))
};
//世纪通信库存  查询所有品牌
export const SJSZInventoryCardType = param => {
	return request.get('/api/SJSZInventory/cardType')
};
//世纪通信库存  查询所有城市
export const SJSZInventorystatistics = param => {
	return request.get('/api/SJSZInventory/statistics?cardType=' + param.cardType + '&&province=' + param.province +
		'&&cityAlphabet=' + param.cityAlphabet)
};

//世纪通信库存  查询所有省份
export const SJSZInventoryprovince = card => {
	return request.get('/api/SJSZInventory/province?cardType=' + card)
};
//世纪通信库存 查询所有出库代理
export const getSalesStaffList = param => {
	return request.get('/api/SJSZInventory/salesStaff')
};
//世纪通信库存 查询所有出库企业
export const getCompanyList = param => {
	return request.get('/api/SJSZInventory/company')
};

//世纪通信库存 导出
export const SJSZInventoryExportRecord = param => {
	return request.post('/api/SJSZInventory/exportRecord', qs.stringify(param), {
		responseType: 'blob'
	})
};
//回拨中间号列表
export const XNumberPool = param => {
	return request.post('/api/callBack/XNumberPool', qs.stringify(param))
};
//添加回拨中间号
export const addXNumber = param => {
	return request.post('/api/callBack/addXNumber', qs.stringify(param))
};
//删除外呼中间号
export const deleteXNumber = param => {
	return request.post('/api/callBack/deleteXNumber', qs.stringify(param))
};


// 回拨套餐列表查询
export const callBackSetMealList = id => {
	return request.get('/api/callBackSetMeal/list')
};
// 回拨套餐添加
export const callBackSetMealAdd = param => {
	return request.post('/api/callBackSetMeal/add', qs.stringify(param))
};
// 回拨套餐修改
export const callBackSetMealUpdate = param => {
	return request.post('/api/callBackSetMeal/update', qs.stringify(param))
};
//回拨套餐删除
export const callBackSetMealDelete = param => {
	return request.post('/api/callBackSetMeal/delete', qs.stringify(param))
};

//回拨消费明细
export const callBackSetMealExpenseDetail = param => {
	return request.post('/api/telA/expenseDetail', qs.stringify(param))
};

//私人号码列表
export const telAList = param => {
	return request.post('/api/telA/list', qs.stringify(param))
};
//添加私人号码
export const telAAdd = param => {
	return request.post('/api/telA/add', qs.stringify(param))
};
//admin添加时长
export const administratorAddPackage = param => {
	return request.post('/api/telA/administratorAddPackage', qs.stringify(param))
};
//购买套餐
export const telABuyMinutes = param => {
	return request.post('/api/telA/buyMinutes', qs.stringify(param))
};
//查询是否支付2S一次
export const telACheckPay = param => {
	return request.post('/api/telA/checkPay', qs.stringify(param))
};
//特服号模版  添加模版
export const packageTemplate = param => {
	return request.post('/api/sip/packageTemplate', qs.stringify(param))
};
//特服号模版  模版列表
export const packageTemplateList = param => {
	return request.post('/api/sip/packageTemplateList', qs.stringify(param))
};
//特服号模版  同步套餐
export const agentAddPackage = param => {
	return request.post('/api/sip/agentAddPackage', qs.stringify(param))
};
//特服号模版  查询代理下企业套餐模板分配情况
export const agentCompanyPackageList = param => {
	return request.post('/api/sip/agentCompanyPackageList', qs.stringify(param))
};
//特服号模版  查询代理模板
export const agentTemplate = param => {
	return request.post('/api/sip/agentTemplate', qs.stringify(param))
};
//特服号模版  代理下企业套餐模板分配
export const companyPackageAllocation = param => {
	return request.post('/api/sip/companyPackageAllocation', qs.stringify(param))
};
//特服号模版  特服号列表
export const accountSetMeal = param => {
	return request.post('/api/sip/accountSetMeal', qs.stringify(param))
};
//特服号模版  特服号划拨
export const accountAssignable = param => {
	return request.post('/api/sip/accountAssignable', qs.stringify(param))
};
//特服号模版  特服号分配
export const transferSipSetMeal = param => {
	return request.post('/api/sip/transferSipSetMeal', qs.stringify(param))
};
//特服号模版  消费明细
export const accountUseRecord = param => {
	return request.post('/api/sip/accountUseRecord', qs.stringify(param))
};
//特服号模版  人脸信息上传
export const uploadFace = param => {
	return request.post('/api/sipVoice/uploadFace', param, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	})
};
//特服号模版  清除人脸信息
export const clearFaceInfo = param => {
	return request.post('/api/sipVoice/clearFaceInfo', qs.stringify(param))
};
//特服号模版  修改人脸信息
export const ModifyFaceState = param => {
	return request.post('/api/sipVoice/ModifyFaceState', qs.stringify(param))
};
//特服号模版  号码列表套餐信息
export const queryPackageList = param => {
	return request.post('/api/sip/queryPackageList', qs.stringify(param))
};
//特服号模版  查询实名人
export const lookTfSipFace = param => {
	return request.post('/api/faceAuthentication/lookTfSipFace', qs.stringify(param), {
		responseType: 'blob'
	})
};
//特服号模版  代理获取模板
export const agentPackageList = param => {
	return request.get('/api/sip/agentPackageList')
};
//特服号模版 数据看板
export const DataBoard = param => {
	return request.post('/api/sip/DataBoard', qs.stringify(param))
};
//sip服务器列表
export const serverList = param => {
	return request.post('/api/sipVoice/serverList', qs.stringify(param))
};
//sip服务器添加
export const serverAdd = param => {
	return request.post('/api/sipVoice/serverAdd', qs.stringify(param))
};
//sip服务器编辑
export const serverUpdate = param => {
	return request.post('/api/sipVoice/serverUpdate', qs.stringify(param))
};
//sip服务器删除
export const serverDelete = param => {
	return request.post('/api/sipVoice/serverDelete', qs.stringify(param))
};

//sip  上传通话记录
export const callRecord = param => {
	return request.post('/api/sipVoice/callRecord', qs.stringify(param))
};

//sip 获取域名等信息
export const server = param => {
	return request.get('/api/sipVoice/server?userId=' + param.userId)
};

//sip  拨打验证过滤
export const sjtxFiltration = param => {
	return request.post('/api/app/sjtxFiltration', qs.stringify(param))
};

//sip  外呼通过后上传记录
export const sipVoiceCall = param => {
	return request.post('/api/sipVoice/call', qs.stringify(param))
};

//sip  拨打查询黑名单
export const verify3 = param => {
	return request.post('/api/blackList/verify3', qs.stringify(param))
};









//工作号模版  添加套餐
export const packageJobTemplate = param => {
	return request.post('/api/sipVoice/packageTemplate', qs.stringify(param))
};
//工作号模版  模版列表
export const packageJobTemplateList = param => {
	return request.post('/api/sipVoice/packageTemplateList', qs.stringify(param))
};
//工作号模版  导入套餐
export const agentJobAddPackage = param => {
	return request.post('/api/sipVoice/agentAddPackage', qs.stringify(param))
};
//工作号模版  查询代理下企业套餐模板分配情况
export const agentCompanyJobPackageList = param => {
	return request.post('/api/sipVoice/agentCompanyPackageList', qs.stringify(param))
};
//工作号模版  查询代理模板
export const agentJobTemplate = param => {
	return request.post('/api/sipVoice/agentTemplate', qs.stringify(param))
};
//工作号模版  代理下企业套餐模板分配
export const companyJobPackageAllocation = param => {
	return request.post('/api/sipVoice/companyPackageAllocation', qs.stringify(param))
};
//工作号模版  特服号列表
export const accountJobSetMeal = param => {
	return request.post('/api/sipVoice/accountSetMeal', qs.stringify(param))
};
//工作号模版  特服号划拨
export const accountJobAssignable = param => {
	return request.post('/api/sipVoice/accountAssignable', qs.stringify(param))
};
//工作号模版  特服号分配
export const transferJobSipSetMeal = param => {
	return request.post('/api/sipVoice/transferSipSetMeal', qs.stringify(param))
};
//工作号模版  消费明细
export const accountJobUseRecord = param => {
	return request.post('/api/sipVoice/accountUseRecord', qs.stringify(param))
};

//工作号模版  号码列表套餐信息
export const queryJobPackageList = param => {
	return request.post('/api/sipVoice/queryPackageList', qs.stringify(param))
};
//工作号模版 数据看板
export const DataJobBoard = param => {
	return request.post('/api/JobNumber/DataBoard', qs.stringify(param))
};

//工作号模版  代理获取模板
export const agentJobPackageList = param => {
	return request.post('/api/sipVoice/agentPackageList', qs.stringify(param))
};


//风险黑名单  列表
export const riskCallList = param => {
	return request.post('/api/blackList/riskCallList', qs.stringify(param))
};
//风险黑名单 添加
export const riskCall = param => {
	return request.post('/api/blackList/riskCall', qs.stringify(param))
};
//风险黑名单删除
export const riskCallDelete = param => {
	return request.post('/api/blackList/riskCallDelete', qs.stringify(param))
};